import SearchHeader from '../../algolia/SearchHeader';
import SearchResults from '../../algolia/SearchResults';
import ExperiencesDataProvider from '../../provider/ExperiencesDataProvider';

export default function Search() {

  return (
    <>
      <ExperiencesDataProvider>
        <SearchHeader/>
        <SearchResults/>
      </ExperiencesDataProvider>
    </>
  );
};