import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ExperiencesDataProvider from './provider/ExperiencesDataProvider';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import Experiences from './pages/Experiences';
import ExperienceNode from './experience/ExperienceNode';
import Layout from './components/Layout';
import Welcome from './pages/welcome_book/Welcome';
import Wifi from './pages/welcome_book/Wifi';
import PageNotFound from './pages/PageNotFound';
import * as PropTypes from 'prop-types';
import Rules from './pages/welcome_book/Rules';
import CheckInOut from './pages/welcome_book/CheckInOut';
import Search from './pages/experiences/Search';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'swiper/css';
import ScrollToTop from './pages/ScrollToTop';
import FAQs from './pages/FAQs';
import ReactGA from "react-ga4";

ReactGA.initialize("G-97CW2V5BXN");

const root = ReactDOM.createRoot(document.getElementById('root'));

function Redirect(props) {
  return null;
}

Redirect.propTypes = { to: PropTypes.string };
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="welcome" element={<Welcome />} />
            <Route path="welcome/wifi" element={<Wifi />} />
            <Route path="welcome/check-in-out" element={<CheckInOut />} />
            <Route path="welcome/rules" element={<Rules />} />
            <Route path="experiences" element={<Experiences />} />
            <Route path="experiences/search" element={<Search />} />
            <Route path="experiences/:id" element={<ExperienceNode />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="*" element={<PageNotFound />} />
            {/*<Route component={PageNotFound} />*/}
            {/*<Route path="page-not-found" element={<PageNotFound />} />*/}
            {/*<Route render={() => <Redirect to="/" />} />*/}
          </Routes>
        </Layout>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
