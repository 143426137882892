import {
  Configure,
  useHits,
} from 'react-instantsearch';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ExperienceCardLarge from '../../experience/ExperienceCardLarge';
import ExperiencesDataProvider from '../../provider/ExperiencesDataProvider';

export default function AlgRecommendedExperiences({ heading, facetFilters = [], filters = '' }) {

  return (
    <div className="py-5 mb-5">
      <ExperiencesDataProvider>
        <Configure
          filters={filters}
          facetFilters={facetFilters}
        />
        <RecommendedSwiper heading={heading}/>
      </ExperiencesDataProvider>
    </div>
  );
}

function RecommendedSwiper(props) {
  const { heading, results, sendEvent } = useHits(props);

  return (
    <>
      <h4 className="mb-3">{heading}</h4>
      <div className="-gutter-x">
        <Swiper
          spaceBetween={20}
          slidesPerView={1.4}
          className="gutter-x"
        >
          {results?.hits.map((item) => (
            <SwiperSlide key={item.id}>
              <Link
                to={`/experiences/${item.id}`}
                className="text-inherit"
                onClick={() => window.scrollTo(0, 0)}
              >
                <ExperienceCardLarge item={item} key={item.id} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}