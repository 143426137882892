import {
  useHits, useRefinementList,
} from 'react-instantsearch';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import * as SolidIcons from '@awesome.me/kit-875b9afcad/icons/sharp/solid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ExperienceCardLarge
  from '../experience/ExperienceCardLarge';

export default function SearchResults(props) {
  const experiences = useSelector((state) => state.experiences);

  return (
    <>
      <SearchResultItems experiences={experiences} {...props} />
    </>
  );
}

function SearchResultItems(props) {
  const { results, sendEvent } = useHits(props);

  return (
    <>
      <div className="py-5">
        {results?.hits.map((hit) => (
          <div
            key={hit.value}
            onClick={() => sendEvent('click', hit, 'Hit Clicked')}
            onAuxClick={() => sendEvent('click', hit, 'Hit Clicked')}
          >
            <SearchResult {...hit} />
          </div>
        ))}
      </div>
    </>
  );
}

function SearchResult(item) {
  const img_path = item?.profile_img;

  if (!img_path) {
    return (
      <div className="bg-red-50 p-3 mb-5">Broken Image; {item.id}</div>
    );
  }

  return (
    <Link className="block mb-5 text-inherit" to={`/experiences/${item.id}`}>
      <ExperienceCardLarge item={item} />
    </Link>
  );
}