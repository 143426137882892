import WelcomeMenu from '../../components/welcome/WelcomeMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong, faBedFront,
} from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import ExperienceCardLarge from '../../experience/ExperienceCardLarge';
import { useEffect, useState } from 'react';

export default function Welcome() {
  const rental = useSelector((state) => state.rental);
  const [hero, setHero] = useState(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if(rental.length > 0) {
      setHero(rental[0]?.rental_images[0])
      setImages(rental[0]?.rental_images?.slice(1))
    }
  }, [rental]);

  return (
    <>
      <WelcomeMenu />

      <div className="aspect-3/2 mb-9 -gutter-e">
        <img src={process.env.REACT_APP_CDN_URL + hero} alt="" className="w-full h-full object-cover"/>
      </div>

      <h1 className="text-4xl mb-0">Escape to Elegance at Buddy Barn:</h1>
      <h2 className="mb-2">A Luxurious Mountain Retreat</h2>

      <div className="flex gap-1 text-2xs opacity-40 mb-9">
        <div>6 guests</div>
        <div>•</div>
        <div>2 bedrooms</div>
        <div>•</div>
        <div>3 beds</div>
        <div>•</div>
        <div>2 bathrooms</div>
      </div>

      <a href="https://www.buddybarngetaway.com/"
         className="block bg-black text-white p-5 pe-10 my-9 -gutter-e"
         target="_blank"
      >
        <div className="flex items-center justify-between gap-5">
          <div>Check Availability</div>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
      </a>

      <div className="flex gap-9 my-9">
        <Bedroom label="Bedroom 1" description="1 king bed" />
        <Bedroom label="Bedroom 2" description="1 queen bed" />
        <Bedroom label="Bedroom 3" description="1 queen bed" />
      </div>


      <h4 className="mb-3">Photos</h4>
      <div className="-gutter-x">
        <Swiper
          spaceBetween={16}
          slidesPerView={1.4}
          className="gutter-x"
        >
          {images?.map((image) => (
            <SwiperSlide key={image.delta}>
              <div className="w-full aspect-3/2 overflow-hidden">
                <img src={process.env.REACT_APP_CDN_URL + image} alt="" className="w-full h-full object-cover object-center overflow-hidden"/>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </>
  );
};

const Bedroom = ({ label, description }) => {
  return (
    <div className="flex flex-col">
      <span><FontAwesomeIcon icon={faBedFront} /></span>
      <span className="text-sm font-semibold">{label}</span>
      <span className="text-2xs opacity-60">{description}</span>
    </div>
  );
};


