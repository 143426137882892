import AlgRecommendedExperiences from './RecommendedExperiences';
import React from 'react';

export default function ExperiencesByActivity({site}) {

  return (
    <AlgRecommendedExperiences
      heading={`${site} Experiences`}
      facetFilters={[`tax_type:${site}`]}
    />
  )

}