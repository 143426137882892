import {
  useRefinementList,
} from 'react-instantsearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-875b9afcad/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import { Title } from '../components/Title';

export default function SearchHeader(props) {
  return (
    <>
      <ActivityRefinement
        {...props}
        attribute={'type_with_icon'}
        sortBy={['name:asc']}
      />
    </>
  );
}

const ActivityRefinement = ({ terms, ...props }) => {
  const { items } = useRefinementList(props);

  return (
    <>
      <div className="flex items-center justify-between gap-2 mb-5">
        <Title>Search</Title>
        {/*<div*/}
        {/*  className="p-3 bg-black text-white flex items-center gap-2 text-sm">*/}
        {/*  <FontAwesomeIcon icon={Icons['faSliders']} className="text-xl" />*/}
        {/*  Filters*/}
        {/*</div>*/}
      </div>

      <div
        className="-gutter-x border-slate-200 border-b sticky top-0 bg-white">
        <Swiper
          spaceBetween={16}
          slidesPerView={4.5}
          className="gutter-x"
        >
          {items.length > 0 && items.map((item) => {
            return (
              <SwiperSlide key={item.label}>
                <ActivityRefinementItem item={item} {...props} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

const ActivityRefinementItem = ({ item, ...props }) => {
  const { refine, sendEvent } = useRefinementList(props);
  const [label, icon] = item.label.split(':');

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center gap-3 py-3 border-b-2 ${item.isRefined ? 'opacity-100 border-black' : 'text-black/60 border-transparent'}`}
        onClick={(event) => {
          refine(item.value);
          sendEvent('click', 'facet', 'price', item.value);
        }}
      >
        {icon &&
          <FontAwesomeIcon icon={byPrefixAndName.fasr[icon]} />
        }
        <div className="text-xs">{label}</div>
      </div>
    </>
  );
};
