import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import { faLocationArrow } from '@awesome.me/kit-875b9afcad/icons/sharp/solid';
import { Badge } from '../components/Badge';
import { decode } from 'he';

export default function ExperienceCardLarge({ item }) {
  const { title, category, distance } = item;
  const image_url = item?.profile_img ?? null;

  return (
    <>
      <div className="relative">
        {image_url &&
          <img
            src={process.env.REACT_APP_CDN_URL + image_url}
            alt={title}
            className="w-full h-full aspect-3/2 object-cover object-center overflow-hidden mb-2"
          />
        }
        {category &&
          <Badge className="absolute top-3 left-3">
            {decode(category)}
          </Badge>
        }
      </div>
      <div className="flex items-start justify-between gap-5">
        <div className="font-medium">{title}</div>
        <FontAwesomeIcon icon={faArrowRight} className="text-sm" />
      </div>

      <div className="flex items-center gap-4 text-2xs font-medium opacity-30">
        {/*<div className="flex items-center gap-2">*/}
        {/*  <FontAwesomeIcon icon={faThumbsUp} className=""/> 22 Guests*/}
        {/*</div>*/}
        <div
          className="flex items-center gap-2">
          <FontAwesomeIcon icon={faLocationArrow} /> {distance} miles
        </div>
      </div>
    </>
  );
}